import { Link } from "remix";

const Navbar = () => {
  return (
    <nav className="py-6 px-4 border-b border-gray-200 flex justify-between">
      <Link to={"/"}>
        <img
          className="w-24"
          src="https://res.cloudinary.com/dkpz9r2q7/image/upload/v1640747063/ryanchenkie_bqekdk.png"
          alt="logo"
        />
      </Link>
      <div className="my-auto">
        <Link
          to={"/about"}
          className="text-xl font-title font-bold text-gray-700 hover:underline"
        >
          About
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
