import type { MetaFunction } from "remix";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "remix";
import Navbar from "./components/Navbar";
import syntax from "./syntax.css";
import styles from "./tailwind.css";

export const meta: MetaFunction = () => {
  return { title: "Ryan Chenkie" };
};

export function links() {
  return [
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: syntax },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Arvo&family=Fira+Code:wght@300;400&family=Montserrat:wght@400;600;700;800&family=Open+Sans:wght@400;500;600;700&family=Rubik:wght@400;600;700&display=swap",
    },
  ];
}

export default function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script
          src="https://cdn.usefathom.com/script.js"
          data-site="HJHGCFPZ"
          defer
        ></script>
        <Links />
        <Meta />
      </head>
      <body className="bg-white h-full container mx-auto px-8">
        <Navbar />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>
  );
}
